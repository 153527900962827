<template>
  <div>
    <div class="d-flex align-items-center">
      <i class="fad fa-sort-up move-el draggable-task-handle"></i>
      <div class="d-block">
        <span class="d-block font-weight-normal">{{ item.icerik[defaultDil].baslik }}</span>
      </div>
    </div>
    <b-button-group>
      <b-button size="sm" variant="warning" tooltip="düzenle" class="rounded-0" @click="$emit('handlerUpdate', item)">
        <i class="fad fa-edit"></i>
      </b-button>
      <b-button size="sm" variant="danger" class="rounded-0" @click="handlerRemove(item.k_no)">
        <i class="fad fa-trash"></i>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, ref } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import Swal from 'sweetalert2';
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Firmaya Ait Tüm Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('kategoriSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme başarılı.', { position: 'bottom-left' });
              context.emit('show', false);
            } else {
              toast.error(res.data.message, { position: 'bottom-left' });
              context.emit('show', false);
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
