<template>
  <li class="list-group-item">
    <ElementItem class="ana-item" :item="item" @handlerUpdate="$emit('handlerUpdate', $event)" />
    <ul>
      <li v-for="(subitem, index) in item.children" :key="index">
        <ElementItem class="sub-item" :item="subitem" @handlerUpdate="$emit('handlerUpdate', $event)" />
      </li>
    </ul>
  </li>
</template>

<script>
import ElementItem from './ElementItem.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {
    ElementItem,
  },
  props: {
    item: {
      type: Object,
      requied: true,
    },
  },
  setup(_, context) {
    const expo = {};
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
